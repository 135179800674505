<template>
  <footer
    role="contentinfo"
    class="relative bg-almostBlack text-white pt-6 z-10 md:py-10"
    :style="{ paddingBottom: 'calc(env(safe-area-inset-bottom) + 1.5rem)' }"
  >
    <div class="container grid grid-cols-1 gap-8 pb-8 md:gap-6 md:grid-cols-12">
      <!-- Logo, Description and Donate button -->
      <div class="md:col-span-3">
        <FrontendHeaderLogo class="w-32 mb-8 md:mb-4" />
        <p class="font-poppins font-light mb-8 md:mb-4">
          The UK’s largest and most comprehensive mental health support directory. Help, when you need it,
          where you need it
        </p>
        <UButton
          label="Donate"
          to="https://www.chasingthestigma.co.uk/donate/"
          target="_blank"
          color="green"
          icon="heroicons:arrow-right-16-solid"
          trailing
          size="xl"
        />
      </div>

      <!-- Main Nav Links -->
      <nav role="navigation" aria-label="footer-navigation-1" class="md:col-start-5 md:col-span-2">
        <ul class="font-brown text-xl">
          <li v-for="link in headerLinks" :key="link.href" class="mb-6 font-bold last:mb-0">
            <NuxtLink :to="link.href" class="hover:text-gray-300 transition-colors duration-200">
              {{ link.name }}
            </NuxtLink>
          </li>
        </ul>
      </nav>

      <!-- T&C's Links -->
      <nav role="navigation" aria-label="footer-navigation-2" class="md:col-start-8 text-base md:col-span-2">
        <ul class="font-brown">
          <li v-for="link in termsLinks" :key="link.href" class="mb-6 font-poppins font-light last:mb-0">
            <NuxtLink :to="link.href" class="hover:text-gray-300 transition-colors duration-200">
              {{ link.name }}
            </NuxtLink>
          </li>
          <li class="space-y-2">
            <p class="font-poppins font-light">Provided by</p>
            <NuxtLink href="https://chasingthestigma.co.uk" target="_blank">
              <img
                src="/static/provided-by-logo.svg"
                alt="Outlines Chasing The Stigma Logo on a dark background"
              />
            </NuxtLink>
            <p class="relative md:absolute font-light">Chasing The Stigma | Registered Charity No 1170757</p>
          </li>
        </ul>
      </nav>

      <!-- App store links -->
      <div class="md:col-start-11 md:col-span-2">
        <p class="mb-4">Get the app</p>
        <FrontendAppStoreLinks :in-footer="true" />
      </div>
    </div>

    <!-- Copyright + Socials -->
    <div class="container">
      <div class="flex flex-wrap gap-6 items-center justify-between border-t border-gray-600 pt-8">
        <p class="font-poppins font-light text-gray-400">
          &copy; {{ new Date().getFullYear() }} Hub of Hope. All rights reserved.
        </p>
        <FrontendSocialLinks />
      </div>
    </div>
  </footer>
</template>
<script setup lang="ts">
const headerLinks = [
  { name: 'Resources', href: '/resources' },
  { name: 'About us', href: '/about-us' },
  { name: 'Register service', href: '/register-your-service' },
  { name: 'Partnerships', href: '/partnerships' },
  { name: 'FAQs', href: '/faqs' },
  { name: 'Urgent help', href: '/need-help-now' },
  { name: 'Get support', href: '/services' }
]
const termsLinks = [
  { name: 'Privacy policy', href: '/privacy-policy' },
  { name: 'Cookie policy', href: '/cookie-policy' },
  { name: 'Disclaimer', href: '/disclaimer' },
  { name: 'Terms & conditions', href: '/terms-conditions' }
]
</script>
