<template>
  <div class="min-h-dvh flex flex-col" :style="{ paddingTop: 'env(safe-area-inset-top)' }">
    <!-- Header -->
    <FrontendMainNav :show-links="true" />

    <!-- Page content -->
    <main role="main" class="flex flex-col grow">
      <slot />
    </main>

    <!-- Toasts -->
    <UNotifications />

    <!-- Footer - only show on web app, not on mobile app -->
    <!-- also not on iframes -->
    <FrontendFooter v-if="!isNative && !isIframe" />

    <!-- native version popup -->
    <NativeUpdateModal v-if="isNative" />
  </div>
</template>

<script lang="ts" setup>
const { isNative } = useRuntimeConfig().public
const { iframeConfig } = storeToRefs(useIframeConfigStore())
const isIframe = computed(() => iframeConfig.value?.uid && iframeConfig.value?.isLive)
</script>
